export const globalPropertiesPlugin = {
    install(app) {
        app.config.globalProperties.appHasLoyalty = window.appHasLoyalty
        app.config.globalProperties.appHasWagering = window.appHasWagering
        app.config.globalProperties.appName = window.appName
        app.config.globalProperties.appUrlName = `${window.appName.toLowerCase()}.co.za`
        app.config.globalProperties.useBitcoinPayments = window.useBitcoinPayments
        app.config.globalProperties.useLiveChat = window.useLiveChat
        app.config.globalProperties.usePumpkinPromo = window.usePumpkinPromo
        app.config.globalProperties.useStockingStashPromo = window.useStockingStashPromo
        app.config.globalProperties.liveChatLicense = window.liveChatLicense
        app.config.globalProperties.useWelcomePackage = window.useWelcomePackage
    }
}
