import HomePage from "@shared/views/Home/HomePage.vue"
import Webplay from "@shared/views/Webplay.vue"
import {createRouter, createWebHistory} from 'vue-router'

const routes = [
    { path: '/', name: 'home', component: HomePage },
    {
        path: '/webplay',
        component: Webplay,
        children: [
            { path: 'all', name: 'webplay.all', component: () => import('@shared/views/Webplay/WebplayAllGames.vue') },
            { path: 'category/:category', name: 'webplay.category', component: () => import('@shared/views/Webplay/WebplayCategory.vue') },
            { path: 'subgroup/:subgroup', name: 'webplay.subgroup', component: () => import('@shared/views/Webplay/WebplaySubGroupGames.vue') },
            { path: 'favourites', name: 'webplay.favourites', component: () => import('@shared/views/Webplay/WebplayFavourites.vue') },
            { path: 'live-casino', name: 'webplay.live-casino', component: () => import('@shared/views/Webplay/WebplayLiveCasino.vue') },
            { path: 'most-popular', name: 'webplay.most-popular', component: () => import('@shared/views/Webplay/WebplayMostPopular.vue') },
            { path: 'new-games', name: 'webplay.new-games', component: () => import('@shared/views/Webplay/WebplayNewGames.vue') },
            { path: 'recent-games', name: 'webplay.recent-games', component: () => import('@shared/views/Webplay/WebplayRecentGames.vue') },
            { path: 'top-games', name: 'webplay.top-games', component: () => import('@shared/views/Webplay/WebplayTopRated.vue') },
            { path: 'game-providers', name: 'webplay.game-providers', component: () => import('@shared/views/Webplay/WebplayGameProviders.vue') },
            { path: 'provider/:provider', name: 'webplay.provider', component: () => import('@shared/views/Webplay/WebplayProvider.vue') },
        ]
    },
    { path: '/game/:gameCode', name: 'game-detail', component: () => import('@shared/views/GameDetail.vue') },
    {
        path: '/platform',
        name: 'platform',
        component: () => import('@shared/views/Webplay.vue'),
        children: [
            { path: 'desktop', name: 'platform.desktop', component: () => import('@shared/views/Webplay/PlatformDesktop.vue') },
            { path: 'mobile', name: 'platform.mobile', component: () => import('@shared/views/Webplay/PlatformMobile.vue') },
        ]
    },
    {
        path: '/bonus-play-games',
        component: () => import('@shared/views/Webplay.vue'),
        children: [
            { path: '', name: 'bonus-play-games', component: () => import('@shared/views/Webplay/BonusPlayGames.vue') },
        ]
    },
    {
        path: '/free-bet-support-games',
        component: () => import('@shared/views/Webplay.vue'),
        children: [
            { path: '', name: 'free-bet-support-games', component: () => import('@shared/views/Webplay/FreebetGames.vue') },
        ]
    },
    {
        path: '/tournaments',
        name: 'tournaments',
        component: () => import('@shared/views/TournamentsPage.vue'),
    },
    {
        path: '/promotions',
        name: 'promotions',
        component: () => import('@shared/views/PromotionsPage.vue'),
    },
    {
        path: '/news/category/:category',
        name: 'news.category',
        component: () => import('@shared/views/News/NewsCategory.vue'),
    },
    {
        path: '/news/:article',
        name: 'news.article',
        component: () => import('@shared/views/News/NewsArticle.vue'),
    },
    {
        path: '/news',
        name: 'news',
        component: () => import('@shared/views/NewsPage.vue'),
    },
    {
        path: '/how-to',
        name: 'how-to',
        component: () => import('@shared/views/HowToPage.vue'),
    },
    {
        path: '/about-us',
        name: 'about-us',
        component: () => import('@shared/views/AboutUsPage.vue'),
    },
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: () => import('@shared/views/PrivacyPolicyPage.vue'),
    },
    {
        path: '/terms-and-conditions',
        name: 'terms-and-conditions',
        component: () => import('@shared/views/TermsAndConditionsPage.vue'),
    },
    {
        path: '/password/reset/:token',
        name: 'password-reset',
        component: () => import('@shared/views/PasswordResetPage.vue'),
    },
    {
        path: '/responsible-gambling',
        name: 'responsible-gambling',
        component: () => import('@shared/views/ResponsibleGamingPage.vue'),
    },
    {
        path: '/capitec',
        name: 'capitec',
        component: () => import('@shared/views/CapitecPage.vue'),
    },
    ...(usePumpkinPromo ?
        [{
            path: '/pumpkin-patch',
            name: 'pumpkin-patch',
            component: () => import('@shared/views/PumpkinPromoPage.vue'),
        }] : []
    ),
    ...(useStockingStashPromo ?
        [{
            path: '/stocking-stash',
            name: 'stocking-stash',
            component: () => import('@shared/views/StockingStashPromoPage.vue'),
        }] : []
    ),
    {
        path: '/unavailable',
        name: 'unavailable',
        component: () => import('@shared/views/UnavailablePage.vue'),
    },
    {
        path: '/:pathMatch(.*)*',
        component: () => import('@shared/views/ErrorPageNotFound.vue'),
    }
]

// Define a variable to hold the singleton router instance
let routerInstance

/**
 * Creates and returns a shared router instance with optional extra routes.
 *
 * @param {Array} [extraRoutes=[]] - Additional routes to either overwrite existing routes or add new ones.
 * @returns {Router} A Vue Router instance configured with the given routes.
 */
const useSharedRouter = (extraRoutes = []) => {
    if (!routerInstance) {
        routerInstance = createRouter({
            history: createWebHistory(),
            scrollBehavior() {
                // always scroll to top
                return {
                    top: 0,
                    behavior: 'smooth'
                }
            },
            routes: [...routes, ...extraRoutes]
        })

        routerInstance.onError((error, to) => {
            if (
                error.message.includes('Failed to fetch dynamically imported module') ||
                error.message.includes('Importing a module script failed')
            ) {
                if (!to?.fullPath) {
                    window.location.reload()
                } else {
                    window.location = to.fullPath
                }
            }
        })
    }

    return routerInstance
}

export default useSharedRouter
